<template>
  <div class="box-page-new">
    <!-- <NavbarOne
      class="d-md-none d-lg-none"
      :showNavbar="showNavbar"
      :showCart="showCart"
      :showCartDesktop="showCartDesktop"
      :showSearch="showSearch"
      :showLogo="showLogo"
    >
    </NavbarOne> -->
    <div class="tab__menus">
      <tabs-menu>
        <template v-slot:header>
          <div class="tabs-link active">
            <li>{{ $t("tab.referral_confirmation") }}</li>
          </div>
          <router-link to="/doctor/referral/complete" class="tabs-link">
            <li>{{ $t("tab.referral_complete") }}</li>
          </router-link>
        </template>
      </tabs-menu>
    </div>
    <div
      class="tab__contents"
      :class="{ 'tab__contents--active': activeTab == 1 }"
    >
      <div class="tab__selects">
        <v-select
          @change="currentDataItems"
          v-model="activeTab"
          :items="listTab"
          item-text="title"
          item-value="id"
          dense
        />
      </div>
    </div>
    <div>
      <v-row class="content-table">
        <v-col cols="4" md="2" class="d-flex align-center">
          <v-select
            v-model="dataPerPage"
            :items="listPerPage"
            item-text="text"
            item-value="value"
            class=""
            :label="$t('per_page')"
            dense
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col cols="8" md="10" class="d-flex align-center justify-end">
          <v-text-field
            class="searchField"
            outlined
            dense
            hide-details
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search') + ' ...'"
          ></v-text-field>
        </v-col>

        <v-col cols="12" class="mt-3">
          <div class="table-responsive">
            <v-data-table
              :headers="headerTable"
              :items="doctorList"
              hide-default-footer
              :items-per-page="dataPerPage"
              :page.sync="pageDataTable"
              class="elevation-1"
              @page-count="pageCountDataTable = $event"
              :mobile-breakpoint="0"
              :search="search"
            >
             

              <template v-slot:item="{ item, index }">
                <tr>
                  <td valign="top" class="pt-2">{{ index + 1 }}</td>
                  <td valign="top" class="pt-2">{{ formatDate(item.created_at) }}</td>
                  <td valign="top" class="pt-2">{{ item.order_number }}</td>
                  <td valign="top" class="pt-2">{{ item.patient }}</td>
                  <td valign="top" class="pt-2">{{ item.patient_id_number }}</td>
                  <td valign="top" class="pt-2">
                    <span
                      v-for="(service, idperdata) in item.product_order"
                      :key="idperdata"
                      ><span class="list-table">
                        {{ service
                        }}{{
                          item.product_order.length - 1 == idperdata
                            ? "."
                            : ", "
                        }}
                      </span>
                    </span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
          <v-row class="mt-6">
            <v-col cols="12" md="10" class="d-flex justify-start">
              <v-pagination
                :total-visible="5"
                v-model="pageDataTable"
                :length="pageCountDataTable"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import API from "@/service/api_service.js";
import Auth from "@/service/auth_service";
// import HeadingTable from "@/components/Heading/HeadingTable.vue";
import TabsMenu from "@/components/general/TabsMenu.vue";
// import NavbarOne from "@/components/v2/topbar/navbar_one.vue";

export default {
  components: {
    TabsMenu,
  },
  data: () => ({
    showNavbar: true,
    showCart: false,
    showCartDesktop: false,
    showSearch: false,
    showLogo: true,
    activeTab: 1,
    search: "",
    pageDataTable: 1,
    pageCountDataTable: 2,
    dataPerPage: 10,
    doctorList: [],
    listPerPage: [
      {
        value: -1,
        text: "All",
      },
      {
        value: 10,
        text: "10",
      },
      {
        value: 25,
        text: "25",
      },
      {
        value: 50,
        text: "50",
      },
    ],
    headerTable: [
      {
        value: "index",
        text: "No.",
        textEn: "No.",
        align: "center",
        sortable: false,
      },
      {
        text: "Tanggal",
        textEn: "Date",
        value: "created_at",
        align: "center",
      },
      {
        text: "No Order",
        textEn: "Order Number",
        value: "order_number",
        align: "center",
      },
      {
        text: "Nama Pasien",
        textEn: "Patient Name",
        value: "patient",
        align: "center",
      },
      {
        text: "ID Pasien",
        textEn: "Patient ID",
        value: "patient_id_number",
        align: "center",
      },

      {
        text: "Deskripsi Pemeriksaan",
        textEn: "Test Description",
        value: "product_order",
        align: "center",
        sortable: false,
      },
    ],
    listTab: [
      {
        id: 1,
        title: "Confirmation",
      },
      {
        id: 2,
        title: "Complete",
      },
    ],
  }),
  created() {
    this.headerTable = [
      {
        textEn: this.$t("page.referral.no"),
        text: "No.",
        align: "center",
        sortable: false,
        value: "index",
      },
      {
        textEn: this.$t("date"),
        text: "Tanggal",
        align: "center",
        sortable: false,
        value: "created_at",
        width: "150px",
         
      },
      {
        textEn: this.$t("page.referral.order_num"),
        text: "No Order",
        align: "center",
        sortable: false,
        value: "order_number",
      },
      {
        textEn: this.$t("page.referral.patient_name"),
        text: "Nama Pasien",
        align: "center",
        sortable: false,
        value: "patient",
         width: "270px",
      },
      {
        textEn: this.$t("page.referral.patient_id"),
        text: "ID Pasien",
        align: "center",
        sortable: false,
        value: "patient_id_number",
      },
      {
        textEn: this.$t("page.referral.exam_desc"),
        text: "Deskripsi Pemeriksaan",
        align: "center",
        sortable: false,
        value: "product_order",
        width: "270px",
      },
    ];
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    await API.get(
      `${process.env.VUE_APP_API_TRUST}referral/get-order?reg_status_code=A04`,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Auth.getToken()}`,
      }
    ).then((res) => {
      if (res.statusCode == 200) {
        this.doctorList = res.results;
      }
    });
    this.$store.commit("setLoading", false);
  },
  methods: {
    currentDataItems() {
      if (this.activeTab == 1) {
        this.$router.push("/doctor/referral/confirmation");
      } else {
        this.$router.push("/doctor/referral/complete");
      }
    },
    formatDate(value) {
      if (!value) return null;
      const MMM = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      const getDate = new Date(value);
      const formated = `${("0" + getDate.getDate()).slice(-2)} ${
        MMM[getDate.getMonth()]
      } ${getDate.getFullYear()} ${
        (getDate.getHours() < 10 ? "0" : "") + getDate.getHours()
      }:${(getDate.getMinutes() < 10 ? "0" : "") + getDate.getMinutes()}:${
        (getDate.getSeconds() < 10 ? "0" : "") + getDate.getSeconds()
      }`;
      return formated;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/custom.scss";
.searchField {
  max-width: 500px;
}
.table-responsive {
  width: 100%;
  overflow-x: auto;
  table {
    min-width: 1100px;
    th {
      position: relative;
    }
    td {
      font-size: 12px !important;
      vertical-align: top !important;
    }
  }
}
.table-default {
  table {
    min-width: 1100px;
    th {
      position: relative;
    }
    td {
      font-size: 12px !important;
      vertical-align: top !important;
    }
  }
}
// custom data table
.v-data-table-header {
  background: #e3e3e38a;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  position: absolute;
}
.button--download {
  background: #79c4c6;
  color: white;
}
.button--active {
  background: $main_3;
  color: white;
}
.button--outline {
  color: $main_3;
  border: 1px solid $main_3;
  background: white;
}
.button-box {
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px 16px;
  outline: none;
}

.title-id {
  margin: 0 !important;
  text-align: center;
}
.list-table {
  margin: 0 !important;
  text-align: left;
  font-size: 12px !important;
}
.title-en {
  margin: 0 !important;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  padding-right: 5px;
}
.tabs {
  background: #e5e5e5 !important;
}
.content-table {
  padding: 35px 18px 18px 18px;
  @media screen and (min-width: 960px) {
    // padding: 35px 18px 18px 36px;
    padding: 16px 25px 18px 33px;
  }
  @media screen and (max-width: 958px) {
    padding: 5px 18px 18px 18px;
  }
}
.box-page-new {
  margin: 20px;
  border-radius: 4px;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
  vertical-align: baseline;
  padding-top: 6px;
  padding-bottom: 6px;
}
</style>
